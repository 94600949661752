

.container-all {
  background: rgb(108,146,124);
  background: linear-gradient(140deg, #6c927c 0%, rgba(51,88,151,1) 62%); 
  height: calc(100% - 58px)!important
}

#root {
  height:100%;
  width: 100%;
}

html, body {
  height:100vh;
  width: 100vw;
  margin:0;
  padding:0;
  overflow-x: hidden;
  
}
.form-control.is-invalid{
  background-image:none!important;
  padding-right: 0!important;
  color:  #dc3545;
  }


  .btn:disabled{
    opacity:0.2!important;
    background-color: medium;
  }

  .btn.disabled:hover{
    background-color: azure!important;
  }

  .page-link:hover{
    background-color: white!important;
  }